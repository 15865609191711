<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="template" v-if="isApplicationLoaded">
      <Home :key="betgamesIframeUrlData.partnerToken"></Home>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Home from '@/pages/Home';
import Loader from '@/components/Loader';

export default {
  name: 'TemplateDefault',
  components: {
    Loader,
    Home,
  },
  computed: {
    ...mapState(['isApplicationLoaded', 'betgamesIframeUrlData', 'loading']),
  },
};
</script>
