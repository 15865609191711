/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { configuration, queryStringParser } from '@/utility';
import router from '@/router';
import store from '@/store';
import App from '@/App';
import { configApi } from '@/api';
import { merge } from 'lodash';

Vue.config.productionTip = false;

new Vue({
  created() {
    if (!window.NSoft) {
      window.NSoft = { queryParams: {} };
    }
    const queryParams = queryStringParser.getQueryParams();
    merge(window.NSoft.queryParams, queryParams);
    configApi
      .getConfig(
        window.NSoft.queryParams.tenantUuid,
        window.NSoft.queryParams.currentGame || 'BGBGBetGamesTv',
      )
      .then((response) => {
        window.NSoft.config = response;
        configuration.init().then(() => {
          this.setInitialConfiguration({ ...window.NSoft, config: response }).then(() => {
            if (!this.auth.token) {
              this.logout();
            }
          });
        });
      });
  },
  computed: {
    ...mapState(['auth', 'betgamesIframeUrlData']),
  },
  methods: {
    ...mapActions(['setInitialConfiguration', 'login', 'logout', 'setLoading']),
  },
  watch: {
    'auth.token': {
      handler(newToken, oldToken) {
        if (newToken && !oldToken) {
          this.setLoading(true);
          this.login({ jwt: newToken, tenantUuid: window.NSoft.queryParams.tenantUuid });
        }
        if (!newToken) {
          this.setLoading(true);
          this.logout();
        }
      },
    },
  },
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
