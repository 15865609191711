export default {
  auth: {
    token: null,
    user: {
      settings: {
        oddType: null,
      },
      profile: {
        language: null,
      },
    },
  },
  tenant: {
    uuid: null,
  },
  queryParams: null,
  betgamesIframeUrlData: {
    oddsFormat: null,
    partnerToken: null,
    timezone: null,
    currentGame: null,
    providerHomePage: null,
    providerPartner: null,
    providerServer: null,
  },
  isApplicationLoaded: null,
  isMobile: null,
  loading: true,
};
