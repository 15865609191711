export default {
  /**
     * Maps a language string from Seven Platform to a Betgames language string.
     * If no language mapping is found, a default string is returned.
     *
     * https://wiki.betgames.tv/index.php?title=List_of_supported_languages
     * @param {string} lang
     */
  mapFromSevenToBetgamesOrDefault(lang, defaultLang = lang) {
    switch (lang) {
      case 'sr-Latn': {
        return 'sr';
      }
      default: {
        return defaultLang;
      }
    }
  },
};
