import { http } from '@/http';
import { urlNormalizer } from '@/utility';

export default {
  login(tenantUuid, language, jwt) {
    return http
      .get(
        urlNormalizer.normalizeUrl(
          `${process.env.VUE_APP_LOGIN_ENDPOINT}?language=${language}&tenantUuid=${tenantUuid}`,
        ),
        { headers: { authorization: jwt } },
      )
      .then((response) => response.data);
  },
};
