<template>
  <simplebar
    ref="simplebar"
    class="home"
    data-simplebar-auto-hide="false"
    data-simplebar-click-on-track="false"
  >
    <div id="frame-container">
      <div id="betgames_div_iframe"></div>
    </div>
  </simplebar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import simplebar from 'simplebar-vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'home',
  components: {
    simplebar,
  },
  data() {
    return {
      bt: null,
    };
  },
  computed: {
    ...mapState(['betgamesIframeUrlData', 'auth', 'isMobile']),
  },
  mounted() {
    this.createScript();
  },
  created() {
    window.addEventListener('message', ({ data }) => {
      const payload = JSON.parse(data);
      if (payload.type === 'iframeTop') {
        this.$refs.simplebar.SimpleBar.getScrollElement().scrollTop = 0;
      }
    });
  },
  methods: {
    ...mapActions(['setLoading']),
    createScript() {
      this.bt = [];
      this.bt.push(['server', this.betgamesIframeUrlData.providerServer]);
      this.bt.push(['partner', this.betgamesIframeUrlData.providerPartner]);
      this.bt.push(['token', this.betgamesIframeUrlData.partnerToken]);
      this.bt.push(['language', this.auth.user.profile.language]);
      this.bt.push(['timezone', this.betgamesIframeUrlData.timezone]);
      this.bt.push(['odds_format', this.auth.user.settings.oddType]);
      this.bt.push(['is_mobile', this.isMobile]);
      this.bt.push(['current_game', this.betgamesIframeUrlData.currentGame]);
      // this.bt.push(['home_url', this.betgamesIframeUrlData.providerHomePage]);
      const betgamesElement = document.createElement('script');
      betgamesElement.setAttribute(
        'src',
        `${
          this.betgamesIframeUrlData.providerServer
        }/design/client/js/betgames.js?ts=${Date.now()}`,
      );
      document.head.appendChild(betgamesElement);
      betgamesElement.onload = () => {
        // eslint-disable-next-line no-undef
        BetGames.frame(this.bt);
        // stupid but necessary to cover sufficient delay AFTER "onload" is fired
        setTimeout(() => {
          this.setLoading(false);
        }, 2000);
      };
    },
  },
};
</script>
<style>
.home {
  height: 100vh;
}
.simplebar-scrollbar::before {
  border-radius: 20px;
  background-color: #ebebeb83;
  transition: opacity 0.2s linear;
}
.simplebar-vertical {
  pointer-events: all !important;
}
</style>
