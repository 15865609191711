import { http } from '@/http';
import { urlNormalizer } from '@/utility';

export default {
  getConfig(tenantUuid, gameName) {
    return http
      .get(
        urlNormalizer.normalizeUrl(
          `${process.env.VUE_APP_CONFIG_ENDPOINT}?tenantUuid=${tenantUuid}&productName=${gameName}`,
        ),
      )
      .then((response) => response.data);
  },
};
