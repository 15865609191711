import { assign, merge } from 'lodash';
import Vue from 'vue';
import { languageMapper } from '@/utility';
import types from './mutationTypes';

export default {
  [types.SET_AUTH](state, payload) {
    assign(state.auth, payload);
  },
  [types.SET_INITIAL_CONFIGURATION](state, payload) {
    const initialData = {
      auth: {
        token: payload.auth?.token || payload.queryParams.jwtToken,
        user: {
          profile: {
            language:
              payload.auth?.user?.profile.language || payload.queryParams.language || payload.language,
          },
          settings: {
            oddType: payload.auth?.user?.settings?.oddType || payload.queryParams.oddsFormat,
          },
        },
      },
      queryParams: payload.queryParams,
      isMobile: payload.queryParams.isMobile,
      betgamesIframeUrlData: {
        currentGame: payload.config.gameId,
        // oddsFormat: payload.queryParams.oddsFormat,
        timezone: payload.queryParams.timezone,
        providerHomePage: payload.config.providerHomePage || payload.queryParams.referrerUrl,
        providerPartner: payload.config.providerPartner,
        providerServer: payload.config.providerServer,
      },
      tenant: {
        uuid: payload.tenant?.uuid || payload.queryParams.tenantUuid,
      },
    };
    merge(state, initialData);
  },
  [types.SET_BETGAMES_IFRAME_URL_DATA](state, payload) {
    const mappedLanguage = languageMapper.mapFromSevenToBetgamesOrDefault(payload.language);
    Vue.set(state.auth.user.profile, 'language', mappedLanguage);
    Vue.set(state.betgamesIframeUrlData, 'partnerToken', payload.token);
    Vue.set(state.betgamesIframeUrlData, 'timezone', payload.timezoneOffset);
  },
  [types.SET_IS_APPLICATION_LOADED](state) {
    state.isApplicationLoaded = true;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
};
