import { authApi } from '@/api';

export default {
  setAuth({ commit }, payload) {
    commit('SET_AUTH', payload);
  },
  setInitialConfiguration: ({ commit }, payload) => {
    commit('SET_INITIAL_CONFIGURATION', payload);
  },
  setBetgamesIframeUrlData: ({ commit }, payload) => {
    commit('SET_BETGAMES_IFRAME_URL_DATA', payload);
  },
  setIsApplicationLoaded: ({ commit }) => {
    commit('SET_IS_APPLICATION_LOADED');
  },
  login: ({ dispatch, state }, { jwt, tenantUuid }) => {
    authApi
      .login(tenantUuid, state.auth.user.profile.language || state.queryParams.language, jwt)
      .then((response) => {
        dispatch('setBetgamesIframeUrlData', response).then(() => {
          if (!state.setIsApplicationLoaded) {
            dispatch('setIsApplicationLoaded');
          }
        });
      });
  },
  logout: ({ dispatch, state }) => {
    dispatch('setBetgamesIframeUrlData', {}).then(() => {
      if (!state.setIsApplicationLoaded) {
        dispatch('setIsApplicationLoaded');
      }
    });
  },
  setLoading: ({ commit }, payload) => {
    commit('SET_LOADING', payload);
  },
};
