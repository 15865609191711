export default {
  init() {
    return new Promise((resolve) => {
      if (window.NSoft.queryParams.integrationMode === 'plain') {
        resolve();
      } else {
        import('../gateway/gateway').then((data) => {
          data.default.init().then(() => {
            resolve();
          });
        });
      }
    });
  },
};
