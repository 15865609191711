<template>
    <div class="loader">
      <div class="loader-container">
        <div class="loader-spinner"></div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss">
.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: $black-opacity-70;
  .loader-container {
    display: flex;
    min-height: 100vh;
    .loader-spinner {
      width: 50px;
      height: 50px;
      border: solid 4px $white;
      border-left-color: $white-opacity-20;
      border-radius: 100%;
      animation: spinner 1s infinite linear;
      margin: auto;
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
